//------------------------------------------------------------------
//	BOOTSTRAP SETUP
//------------------------------------------------------------------
@import 'common/variables';
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';
@import 'node_modules/bootstrap/scss/utilities';
@import 'node_modules/bootstrap/scss/badge';
@import 'node_modules/mlmi-library/components/admin/v1/admin';
@import 'node_modules/mlmi-library/components/builder/v1/mixins';

@each $product-category, $color-value in $product-categories {
  .badge-#{$product-category} {
    background-color: $color-value;
  }
}

label[for='_visibility_hidden'] + br,
label[for='_visibility_hidden'] + br + br,
label[for='_visibility_hidden'] ~ input[name='_featured'],
label[for='_visibility_hidden'] ~ label[for='_featured'],
label[for='_visibility_hidden'] ~ label[for='_featured'] + br {
  display: none !important;
}

tr.catalog-hidden td.column-name strong {
  color: #d12533;
}

/*
* Groupe ACF: Hotspot
*/
#acf-group_core_hotspot_group {
  th.acf-th[data-key='hotspot_point_x_group'],
  th.acf-th[data-key='hotspot_point_y_group'] {
    display: none !important;
  }

  .smallest-editor > .acf-input {
    margin-top: -6px;
  }

  .acf-field[data-name='hotspot_image_group'] {
    .image-wrap {
      width: 100% !important;
      overflow: hidden;

      > img {
        pointer-events: none;
      }

      .map-point {
        position: absolute !important;
        top: 50%;
        left: 50%;
        width: 1px;
        height: 1px;
        cursor: grab;

        &::before {
          content: attr(data-index);
          display: block;
          width: 33px;
          height: 33px;
          width: 33px;
          line-height: 33px;
          font-size: 14px;
          text-align: center;
          border: 1px solid #000;
          background: transparent;
          border-radius: 50%;
          transform: translate(-50%, -50%);
          pointer-events: all;
          background: #fff;
        }
      }
    }
  }
}
